import React, { useState, useEffect } from "react";


import { DataGrid } from '@material-ui/data-grid';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container : {
    width : '100%',
    backgroundColor : '#efefef',
    padding : '10px',
    maxWidth : '100vw'
  },
  listContainer : {
    height : '350px',
    width : '100%',
    textAlign : 'center'
  }
}));

const columns = [
  { field: 'id', hide : true },
  { field: 'created', headerName: 'Date Joined', flex: 1 },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'website', headerName: 'Title', flex: 1 },
  { field: 'industry', headerName: 'Industry', flex: 1 },
  { field: 'address', headerName: 'Location', flex: 1, renderCell : (val) => {
    const address = val.value;
    return (
      <>
        {address.city}, {address.state}
      </>
    )
  }}
];

export default function Alerts(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [companies, setCompanies] = useState(props.companies);

  useEffect(() => {
    setCompanies(props.companies);
  }, [props.companies])

  return (
    <Grid container spacing={3}>
      <Grid xs={12} sm={12} item>
        <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
          <Typography variant="h6">Companies Registered</Typography>
          <div className={classes.listContainer}>
            <DataGrid
              rows={companies}
              disableColumnMenu
              columns={columns}
              pageSize={5}
              sortModel={[{field: 'name', sort: 'desc'}]}
              components={{
                NoRowsOverlay : () => (<div style={{margin:'auto auto'}}>No Past Alerts</div>)
              }}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>

  );
}
