import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { isMobile } from "react-device-detect";

import Home from './Sections/Home';
import Billing from './Sections/Billing';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PaymentIcon from '@material-ui/icons/Payment';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';

import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = isMobile ? window.screen.width * 0.8 : 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink : 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
    flexGrow:1
  },
  content: {
    padding: theme.spacing(3),
    paddingTop: '0px',
    backgroundColor : '#565656',
    height : '100%',
    maxWidth:'100%'
  }
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const history = useHistory();
  const [selected, setSelected] = useState(props.page !== '' ? props.page : 'home');

  const renderSection = (section) => {
    switch (section) {
      case 'billing':
        return (<Billing {...props} />);
      default:
        return (<Home {...props} />);
    }
  }

  const navigate = (page) => {
    history.push(page);
    setSelected(page);
  };

  return (
    <>
      <Drawer
        className={ classes.drawer }
        variant={ isMobile ? "temporary" : "permanent" }
        classes={{ paper: classes.drawerPaper }}
        open={ props.mobileOpen }
        onClose={ props.handleDrawerToggle }>
        <Toolbar />
        <div className={ classes.drawerContainer }>
          <List>
            <ListItem selected={selected === 'dashboard'} onClick={() => navigate('dashboard')} button key="dashboard">
              <ListItemIcon><DashboardIcon></DashboardIcon></ListItemIcon>
              <ListItemText classes={{root:classes.listItemText}} primary="View Companies" />
            </ListItem>
            <ListItem selected={selected === 'billing'} onClick={() => navigate('billing')} button key="billing">
              <ListItemIcon><PaymentIcon></PaymentIcon></ListItemIcon>
              <ListItemText classes={{root:classes.listItemText}} primary="Billing" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <div style={{display:'inline-block', flexGrow:'1', minHeight : '100vh', maxWidth : '100vw'}}>
        <main className={classes.content}>
          <Toolbar />
          {
            renderSection(selected)
          }
        </main>
      </div>

    </>
  );
}
