import React, { useState, useEffect } from "react";

import { useEffectWhen } from '../../../libs/hooks'
import { DataGrid } from '@material-ui/data-grid';
import { API } from "aws-amplify";
import CurrencyFormat from 'react-currency-format';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container : {
    padding : '10px'
  },
  formControl : {
    display:'block'
  },
  listContainer : {
    height : '350px',
    width : '100%',
    textAlign : 'center'
  }
}));

const calculateTotal = (total) => {
  var amount = 0;
  for (var i = 1; i <= total; i++) {
    if (i <= 250) {
      amount += 5;
    } else if (i > 250 && i <= 500) {
      amount += 4;
    } else if (i > 500 && i <= 1000) {
      amount += 3;
    } else if (i > 1000 && i <= 2500) {
      amount += 2.5;
    } else if (i > 2500 && i <= 5000) {
      amount += 2;
    } else if (i > 5000) {
      amount += 1;
    }
  }
  return amount;
}

const columns = [
  { field: 'id', hide : true },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'total', headerName: 'Total', flex: 1, renderCell : (val) => {
    const total = val.value;
    return (
      <CurrencyFormat value={ calculateTotal(total) } displayType={'text'} thousandSeparator={true} prefix={'$'} />
    )
  }}
];

export default function Alerts(props) {
  const classes = useStyles();
  const theme = useTheme();

  const today = new Date();

  const [rows, setRows] = useState([]);
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, []);

  const load = () => {
    API.get('budeeBackend', 'invoice/?year=' + year + '&month=' + month, {}).then(res => {
      var data = [];
      for (const invoice of res) {
        data.push({
          id : invoice.companyId,
          name : invoice.payee.details.name,
          total : invoice.total
        });
      }
      setRows(data);
    });
  }

  useEffectWhen(() => {
    if (year && month) {
      load();
    }
  }, [year, month, load], [year, month]);

  return (
    <Grid container spacing={3}>
      <Grid xs={12} sm={12} item>
        <Paper style={{ padding : '10px', marginTop:'10px', border : '3px solid ' + theme.palette.secondary.main }}>
          <Typography variant="h6">Invoicing</Typography>
          <div className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                <MenuItem value={'2021'}>2021</MenuItem>
                <MenuItem value={'2022'}>2022</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.listContainer}>
            <DataGrid
              rows={rows}
              disableColumnMenu
              columns={columns}
              pageSize={5}
              sortModel={[{field: 'name', sort: 'desc'}]}
              components={{
                NoRowsOverlay : () => (<div style={{margin:'auto auto'}}>No Invoices for this Period</div>)
              }}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
