import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dashboard from '../components/Dashboard/Dashboard';

import { useParams } from "react-router";

export default function Home(props) {
  let { page } = useParams();

  return (
    <>
    {
      props.loading &&
      <div style={{margin:'100px auto'}}>
        <CircularProgress size={80} />
      </div>
    }
    {
      !props.loading &&
      <Dashboard page={page} {...props} />
    }
    </>
  );
}
