const dev = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://2onqwts8yl.execute-api.us-west-2.amazonaws.com/dev/"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_6HhsUH7Jf",
    APP_CLIENT_ID: "3ellivcrqboakbipoa34u476pp",
    IDENTITY_POOL_ID: "us-west-2:187140bc-9ae5-4d36-8b01-b7e16517230e"
  },
  s3 : {
    BUCKET : "dev-budeemedia",
    REGION : "us-west-2"
  }
};

const prod = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://kms9mkzxce.execute-api.us-west-2.amazonaws.com/prod/"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_ISdTByupm",
    APP_CLIENT_ID: "7rjj2h87o20rjfvq4mblvl15pg",
    IDENTITY_POOL_ID: "us-west-2:279a19f7-37a2-4f05-9105-e8e718d09b8d"
  },
  s3 : {
    BUCKET : "prod-budeemedia",
    REGION : "us-west-2"
  }
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default config;
