import { useState, useEffect, useRef } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      if (Array.isArray(event.target)) {
        var newFields = {};
        for (const val of event.target) {
          newFields[val.id] = val.value;
        }
        setValues({
          ...fields,
          ...newFields
        });
      } else {
        if (event.target.id || event.target.name) {
          setValues({
            ...fields,
            [event.target.id ? event.target.id : event.target.name]: event.target.value
          });
        }
      }
    }
  ];
}

export function useEffectWhen(effect, deps, whenDeps) {
  const whenRef = useRef(whenDeps || []);
  const initial = whenRef.current === whenDeps;
  const whenDepsChanged = initial || !whenRef.current.every((w, i) => w === whenDeps[i]);
  whenRef.current = whenDeps;
  const nullDeps = deps.map(() => null);

  return useEffect(
    whenDepsChanged ? effect : () => {},
    whenDepsChanged ? deps : nullDeps
  );
}
