import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
// import AdministrativeRoute from "./components/AdministrativeRoute";

export default ({ appProps }) =>
  <Switch>
    <AuthenticatedRoute path="/" exact component={Home} appProps={appProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
    <AuthenticatedRoute path="/:page" exact component={Home} appProps={appProps} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
