import React, { useState } from "react";
import { Auth } from 'aws-amplify';

import { useFormFields } from "../libs/hooks";
import queryString from 'query-string';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  main : {
    width : '40vw',
    maxWidth : '500px',
    minWidth : '300px',
    margin : '0 auto',
    marginTop : '64px',
    paddingTop : '15vh',
    textAlign:'right'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  unrecognized: {
    color:'red'
  },
  container:{
    textAlign:'left'
  },
  link : {
    cursor : 'pointer',
    marginTop:'100px'
  }
}));

export default function Login(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [unrecognized, setUnrecognized] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [user, setUser] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    code: "",
    newPassword:""
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    // Check if the URL contained a redirect parameter
    const urlParams = queryString.parse(props.location.search);
    var redirect;
    if (urlParams.redirect) {
      redirect = urlParams.redirect;
    } else {
      redirect = "/";
    }

    props.handleLogin(fields.email, fields.password, redirect)
      .then(result => {
        setIsLoading(false);
        if (!result)
          setUnrecognized(true);
        if (result && result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setNewPasswordRequired(true);
          setUser(result);
        }
      })
      .catch(e => console.error(e));
  }

  async function handleForgotPasswordSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.forgotPassword(fields.email);
      setConfirmNewPassword(true);
    } catch (e) {
      alert(e.message);
    }
    setIsLoading(false);
  }

  async function handleConfirmNewPasswordSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.forgotPasswordSubmit(fields.email, fields.code, fields.newPassword);
      setForgotPassword(false);
      setConfirmNewPassword(false);
    } catch (e) {
      alert(e.message);
    }
    setIsLoading(false);
  }

  async function handleNewPasswordRequired(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      Auth.completeNewPassword(
        user,
        fields.newPassword,
      ).then(user => {
        window.location = '/dashboard';
      }).catch(e => {
        console.error(e);
      });
    } catch (e) {
      alert(e.message);
    }
    setIsLoading(false);
  }

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0
    );
  }

  return (
    <div className={classes.main}>
      <div>
        <Typography variant="h6">Are you a customer?</Typography>
        <Link href="https://app.budeesolutions.com" variant="body2">
          Proceed to Customer Login
        </Link>
      </div>
      <div className={classes.container}>
        <Typography component="h1" variant="h5">
          { !forgotPassword && 'Administrative Login' }
          { forgotPassword && 'Password Reset' }
        </Typography>
        {
          !newPasswordRequired && !forgotPassword && !confirmNewPassword &&
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              size="medium"
              autoFocus
              onChange={handleFieldChange}
            />
            <div style={{display:'flex', alignItems:'center'}}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={ showPassword ? "text" : "password" }
                id="password"
                autoComplete="current-password"
                size="medium"
                onChange={handleFieldChange}
                style={{marginRight:'10px'}}
              />
              {
                showPassword &&
                <VisibilityIcon onClick={() => setShowPassword(!showPassword)}/>
              }
              {
                !showPassword &&
                <VisibilityOffIcon onClick={() => setShowPassword(!showPassword)} />
              }
            </div>
            {
              unrecognized &&
              <p className={classes.unrecognized}>Unrecognized email and password.</p>
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!validateForm()}
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        }
        {
          !forgotPassword && !newPasswordRequired &&
          <>
            {
              !props.checkout &&
              <>
                <Grid container>
                  <Grid item xs>
                    <div style={{width:'100%', display: 'flex', flexDirection : 'row'}}>
                      <Link href="#" onClick={() => setForgotPassword(true)} variant="body2">
                        Forgot password?
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            { isLoading &&
              <div style={{textAlign:'center'}}>
                <CircularProgress size={80} />
              </div>
            }
          </>
        }
        {
          forgotPassword && !confirmNewPassword &&
          <form className={classes.form} onSubmit={handleForgotPasswordSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleFieldChange}
              value={fields.email}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send Code
            </Button>
          </form>
        }
        {
          newPasswordRequired && !forgotPassword &&
          <form className={classes.form} onSubmit={handleNewPasswordRequired} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Reset Password"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              onChange={handleFieldChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
          </form>
        }
        {
          forgotPassword && confirmNewPassword &&
          <form className={classes.form} onSubmit={handleConfirmNewPasswordSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              label="Verification Code"
              name="code"
              autoFocus
              onChange={handleFieldChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              onChange={handleFieldChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Set New Password
            </Button>
          </form>
        }
        { forgotPassword && isLoading &&
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress size={80} />
          </div>
        }
        {
          forgotPassword &&
          <>
            <Grid container>
              <Grid item xs>
                <Link href="#" onClick={() => setForgotPassword(false)} variant="body2">
                  Back to Login
                </Link>
              </Grid>
            </Grid>
          </>
        }
      </div>
    </div>
  );
}
