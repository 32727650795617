import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";

import { Auth } from "aws-amplify";
import User from './components/Authentication/User';
import { API } from "aws-amplify";
import { isMobile } from "react-device-detect";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';

import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary : {
      main : "#005eff"
    },
    secondary : {
      main : "#59c2ff"
    },
    accentColor : {
      main : "#13752a"
    }
  },
  typography: {
    fontFamily: [
      'Nunito'
    ]
  }
});

const useStyles = makeStyles((theme) => ({
    title : {
      flexGrow: 1
    },
    appBar : {
      backgroundColor : "#005eff",
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    }
}));

function App(props) {
  const classes = useStyles();

  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [companies, setCompanies] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(null);
  const [isAuthenticating, setAuthenticating] = useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(isMobile ? false : true);

  const menuId = 'primary-search-account-menu';

  useEffect(() => {
    checkAuthentication();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated !== null) {
      setAuthenticating(false);
    }
  }, [isAuthenticated]);

  async function checkAuthentication() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setSession(await Auth.currentSession());
      setUser(new User(user.attributes));
      userHasAuthenticated(true);
      Promise.all([
        loadCompanies()
      ]).then((values) => {
        setLoading(false);
      });
      return user.attributes;
    } catch(e) {
      if (e !== 'not authenticated') {
        console.error(e);
      }
    }
    props.history.push({pathname:'/login',search:props.location.search});
    userHasAuthenticated(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(null);
    props.history.push({pathname:'/login',search:props.location.search});
  }

  async function handleLogin(email, password, redirect) {
    try {
      const result = await Auth.signIn(email, password);
      if (result && result.challengeName === "NEW_PASSWORD_REQUIRED") {
        return result;
      } else {
        window.location = redirect;
        return true;
      }
    } catch (e) {
      if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
        return false;
      } else {
        console.error(e);
      }
    }
  }

  async function refreshSession() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, (err, newSession) => {
        setSession(newSession);
      });
    } catch (e) {
      console.error('Unable to refresh Token', e);
    }
  }

  async function loadCompanies() {
    return API.get("budeeBackend", "company/?" + new Date().getTime(), {}).catch(err => {
        console.error(err);
      }).then((res) => setCompanies(res));
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getBaseUrl = () => {
    if (process.env.REACT_APP_STAGE === 'prod') {
      return 'https://prod-budeestatic.s3.us-west-2.amazonaws.com';
    } else {
      return 'https://dev-budeestatic.s3.us-west-2.amazonaws.com';
    }
  }

  return (
    <>
      {
        !isAuthenticating &&
        <div id="budee-app" style={{display:'flex'}}>
          <AppBar classes={{root:classes.appBar}} position="fixed">
            <Toolbar>
            {
              isMobile &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            }
            <Typography variant="h6" className={classes.title}>
            {
              !isMobile &&
              <img height="30px" src="./budee-logo-white.png" alt="Budee Solutions Inc." />
            }
            </Typography>
            {
              isAuthenticated &&
              <div>
                {
                  user &&
                  <div style={{color : '#ffffff', display : 'inline-block', paddingRight:'10px', borderRight:'1px solid #FFFFFF'}}>{ user.email }</div>
                }
                <Button onClick={() => handleLogout()} color="inherit">Logout</Button>
                <IconButton
                  edge="end"
                  aria-label="current user account"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => window.location = '/user'}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
            }
            </Toolbar>
          </AppBar>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Routes appProps={{
                isAuthenticated,
                handleLogin,
                handleLogout,
                isAuthenticating,
                checkAuthentication,
                user,
                companies,
                session,
                refreshSession,
                loading,
                mobileOpen,
                handleDrawerToggle,
                getBaseUrl }} />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </div>
      }
    </>
  )
}

export default withRouter(App);
