import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ component: C, appProps: cProps, ...rest }) =>
    <Route
      {...rest}
      render={props =>
        cProps.isAuthenticating ? <></> : cProps.isAuthenticated !== null ? <C {...props} {...cProps} /> : <Redirect to={`/login`}/>
      }
    />
